import { slugify } from "../../utils/slugify.js";
import type { Category } from "../Category.js";
import type { CategorySeoPage } from "../CategorySeoPage.js";
import type { Locale } from "../Locale.js";

export function getCategoryPath(
	locale: Locale,
	{ name, seoPages }: Pick<Category, "name" | "seoPages">,
	seoPage?: CategorySeoPage,
): string {
	const matchingSeoPage = seoPage && seoPages.find((page) => page.type === seoPage.type);
	if (matchingSeoPage) {
		return slugify(matchingSeoPage.metadata.title[locale]);
	}

	return slugify(name[locale]);
}
